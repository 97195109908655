import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Feedback } from './Feedback'

const client = new QueryClient()
export default function FeedbackApp({ layoutClassName = undefined }) {
  return (
    <QueryClientProvider {...{ client }} >
      <Feedback {...{ layoutClassName }} />
    </QueryClientProvider>
  )

}

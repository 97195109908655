import { useFormField, useBooleanFormField } from '@kaliber/forms'
import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'
import iconCheck from '/images/icons/check.raw.svg'
import styles from './FormField.css'

export function FormFieldCheckbox({ field, label, description, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentCheckbox} {...{ field, label, required }} >
      <InputCheckbox label={description} {...{ field }} />
    </FormFieldBase>
  )
}

export function FormFieldSelect({ field, label, options, placeholder = undefined, description = undefined, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentSelect} {...{ field, label, placeholder, description, required }} >
      <InputSelect {...{ field, placeholder, options }} />
    </FormFieldBase>
  )
}

export function FormFieldTextarea({
  field,
  label = undefined,
  placeholder = undefined,
  maxLength = undefined,
  description = undefined,
  required = undefined,
  layoutClassName = undefined
}) {
  return (
    <FormFieldBase className={cx(styles.componentTextarea, layoutClassName)} {...{ field, label, description, required }} >
      <InputTextarea layoutClassName={styles.inputTextAreaLayout} {...{ field, placeholder, maxLength }} />
    </FormFieldBase>
  )
}

export function FormFieldNumber({ field, label, placeholder = undefined, description = undefined, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentNumber} {...{ field, label, placeholder, description, required }} >
      <InputNumber {...{ field, placeholder }} />
    </FormFieldBase>
  )
}

export function FormFieldEmail({ field, label, placeholder = undefined, description = undefined, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentEmail} {...{ field, label, placeholder, description, required }} >
      <InputText type='email' {...{ field, placeholder }} />
    </FormFieldBase>
  )
}

export function FormFieldText({ field, label, placeholder = undefined, description = undefined, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentText}  {...{ field, label, placeholder, description, required }} >
      <InputText type='text' {...{ field, placeholder }} />
    </FormFieldBase>
  )
}

function FormFieldBase({ className, field, label, children, description = undefined, required = undefined }) {
  const { name, state } = useFormField(field)
  const { error, showError } = state

  return (
    <div className={cx(styles.componentBase, className)}>
      {label && <Label htmlFor={name} {...{ label, required }} />}
      {children}
      {description && <Description id={`${name}-description`} {...{ name, description }} />}
      {showError && <ErrorMessage id={`${name}-text-field-error`} {...{ name, error }} />}
    </div>
  )
}

function InputText({ field, type, placeholder }) {
  const { name, state, eventHandlers } = useFormField(field)

  return (
    <input
      id={name}
      aria-describedby={`${name}-description ${name}-error`}
      className={styles.componentInputText}
      value={state.value}
      {...{ type, placeholder }}
      {...eventHandlers}
    />
  )
}

function InputNumber({ field, placeholder }) {
  const { name, state, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)

  return (
    <input
      id={name}
      aria-describedby={`${name}-description ${name}-error`}
      className={styles.componentInputNumber}
      value={state.value}
      type='text'
      inputMode='numeric'
      onChange={handleChange}
      {...{ placeholder }}
      {...eventHandlers}
    />
  )

  function handleChange(e) {
    const value = e.target.value
    if (!value || value === String(Number(value))) onChange(value)
  }
}

function InputTextarea({ field, placeholder, maxLength = undefined, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  const inputRef = React.useRef(null)

  const { name, state, eventHandlers } = useFormField(field)
  const { value, hasFocus } = state
  const valueLength = value?.length

  return (
    <div
      className={cx(
        styles.componentInputTextarea,
        hasFocus && styles.hasFocus,
        maxLength && styles.hasMaxLength,
        layoutClassName
      )}>
      <textarea
        ref={inputRef}
        id={name}
        value={state.value}
        aria-describedby={`${name}-description ${name}-error`}
        className={styles.inputTextarea}
        {...{ placeholder, maxLength }}
        {...eventHandlers}
      />
      {maxLength && (
        <p onClick={focusTextArea} className={styles.valueLengthCounter}>
          {valueLength
            ? __({ length: valueLength, maxLength })`x-of-y-characters`
            : __({ maxLength })`max-characters`
          }
        </p>
      )}
    </div>
  )

  function focusTextArea() {
    inputRef?.current?.focus()
  }
}

function InputSelect({ field, placeholder = undefined, options }) {
  const { name, state, eventHandlers } = useFormField(field)
  const showPlaceholder = !state.value && placeholder

  return (
    <div className={styles.componentInputSelect}>
      <select className={cx(styles.select, showPlaceholder && styles.placeholderShown)} value={state.value} aria-describedby={`${name}-description`} {...eventHandlers}>
        {showPlaceholder &&
          <option key='' value=''>{placeholder}</option>
        }

        {options.map((option, i) => (
          <option key={i} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  )
}

function InputCheckbox({ field, label }) {
  const { name, state, eventHandlers } = useBooleanFormField(field)
  const { value: checked, hasFocus } = state

  return (
    <div className={styles.componentInputCheckbox}>
      <label htmlFor={name} className={styles.checkboxLabel}>{label}</label>

      <input
        id={name}
        type='checkbox'
        className={styles.inputCheckbox}
        aria-describedby={`${name}-description ${name}-error`}
        value={checked}
        {...{ checked, name }}
        {...eventHandlers}
      />

      <div className={cx(styles.checkbox, checked && styles.isChecked, hasFocus && styles.hasFocus)}>
        <Icon icon={iconCheck} />
      </div>
    </div>
  )
}

function Label({ label, htmlFor, required = undefined, layoutClassName = undefined }) {
  return (
    <label className={cx(styles.componentLabel, layoutClassName)} {...{ htmlFor }}>
      {label} {required && '*'}
    </label>
  )
}

function Description({ id, description }) {
  return <span  className={styles.componentDescription} {...{ id }}>{description}</span>
}

function ErrorMessage({ id, error }) {
  const { __ } = useTranslate()

  const validationErrors = {
    required: __`form-validation-required`,
    requiredTrue: __`form-validation-required`,
    email: __`form-validation-email`,
    number: __`form-validation-number`,
  }

  return <div className={styles.componentError} {...{ id }}>{validationErrors[error.id]}</div>
}

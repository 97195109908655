import { useFloating, useInteractions, useDismiss, FloatingOverlay as FloatingOverlayBase, useRole, useFocus } from '@floating-ui/react'
import { useForm, useFormField, snapshot } from '@kaliber/forms'
import { FormFieldValue } from '@kaliber/forms/components'
import { required, optional } from '@kaliber/forms/validation'
import { translateSingular } from '/i18n/translations'
import { useMutation } from '@tanstack/react-query'
import { signInAnonymously, getAuth } from 'firebase/auth'
import { getDatabase, push, ref, serverTimestamp, update } from 'firebase/database'
import { useFirebaseApp } from '/machinery/firebase'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useReportError } from '/machinery/ReportError'
import { HeadingXs } from '/features/buildingBlocks/Heading'
import { FormFieldTextarea } from '/features/buildingBlocks/FormField'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Feedback.css'

import iconMark from '/images/icons/mark.raw.svg'
import iconArrowRight from '/images/icons/arrow-right.raw.svg'
import heartImage from '/images/heart.png'


export function Feedback({ layoutClassName = undefined }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const { getFloatingProps } = useFloatingProps({ isOpen, handleOpenChange })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.container} {...getFloatingProps()}>
        <FeedbackButton onClick={handleOpenChange} layoutClassName={styles.buttonLayout} {...{ isOpen }} />
        <FeedbackContent onDismissClick={handleOpenChange} layoutClassName={styles.contentLayout} {...{ isOpen }} />
      </div>
      <FloatingOverlayBase className={cx(styles.overlay, isOpen && styles.isOpen)} />
    </div>
  )

  function handleOpenChange() {
    setIsOpen(!isOpen)
  }
}

function FeedbackButton({ onClick, isOpen, layoutClassName = undefined }) {
  return (
    <button data-x='click-to-expand-feedback' className={cx(styles.componentButton, layoutClassName)} {...{ onClick }}>
      <span className={cx(styles.drawerButtonLabel, isOpen && styles.isOpen)}>Feedback</span>
      <span className={cx(styles.drawerButtonIconContainer, isOpen && styles.isOpen)}>
        <Icon icon={iconMark} layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )
}

function FeedbackContent({ isOpen, onDismissClick, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentContent, isOpen && styles.isOpen, layoutClassName)}>
      <div className={styles.contentContainer}>
        <div className={styles.contentInnerContainer}>
          <FeedbackFormContent {...{ onDismissClick }} />
        </div>
      </div>
    </div>
  )
}

function FeedbackFormContent({ onDismissClick }) {
  const [isSuccess, setIsSuccess] = React.useState(false)
  const { __ } = useTranslate()

  return (
    <div className={styles.componentFormContent}>
      {!isSuccess && (
        <>
          <HeadingXs h={4}>{__`what-do-you-think`} <span className={styles.noLineBreak}>Rabo &Co?</span></HeadingXs>
          <p className={styles.text}>{__`what-do-you-think-description`}</p>
          <FeedbackForm onSuccess={setIsSuccess} />
        </>
      )}

      {isSuccess && (
        <FeedbackSentContent {...{ onDismissClick }} />
      )}
    </div>
  )
}

function FeedbackSentContent({ onDismissClick }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentSentContent}>
      <HeadingXs h={4}>{__`thanks-for-feedback`} <span className={styles.noLineBreak}>Rabo &Co?</span></HeadingXs>
      <p className={styles.text}>{__`thanks-for-feedback-description`}</p>
      <img src={heartImage} alt='A 3d render of a heart' className={styles.heartImage} />
      <DismissButton onClick={onDismissClick} />
    </div>
  )
}

function FeedbackForm({ onSuccess }) {
  const { __ } = useTranslate()
  const reportError = useReportError()
  const language = useLanguage()
  const firebaseApp = useFirebaseApp('feedback-form')
  const firebaseLocationReferenceRef = React.useRef(null)

  const { form, submit } = useForm({
    initialValues: { satisfaction: '', explanation: '' },
    fields: { satisfaction: required, explanation: optional },
    onSubmit: handleSubmit,
  })

  const { fields } = form

  const { mutate } = useMutation({
    mutationFn: storeFormValuesInFirebase,
    onError: reportError
  })

  const { state: { value: satisfactionValue } } = useFormField(fields.satisfaction)

  return (
    <form className={styles.componentForm} onSubmit={submit}>
      <EmojiRadioGroup
        field={fields.satisfaction}
        onChange={handleSatisfactionChange}
        options={[
          { label: __`very-bad`, value: 'very-bad', emoji: '😓' },
          { label: __`bad`, value: 'bad', emoji: '🙁' },
          { label: __`average`, value: 'average', emoji: '😐' },
          { label: __`good`, value: 'good', emoji: '😃' },
          { label: __`very-good`, value: 'very-good', emoji: '😍' },
        ]}
      />
      <FormFieldValue field={fields.satisfaction} render={value => (
        <div className={cx(styles.conditionalContainer, value && styles.hasValue)}>
          <div className={styles.conditionalFields}>
            <FormFieldTextarea
              field={fields.explanation}
              maxLength={500}
              layoutClassName={styles.textareaLayout}
              placeholder={getPlaceholderText(language, satisfactionValue)}
            />
            <SubmitButton />
          </div>
        </div>
      )} />
    </form>
  )

  function handleSatisfactionChange(x) {
    mutate(snapshot.get(form).value, {
      onSuccess: firebaseRef => { firebaseLocationReferenceRef.current = firebaseRef }
    })
  }

  function handleSubmit(snapshot) {
    mutate(snapshot.value, {
      onSuccess: () => onSuccess(true)
    })
  }

  async function storeFormValuesInFirebase(formValues) {
    const { user: { uid } } = await signInAnonymously(getAuth(firebaseApp))

    if (firebaseLocationReferenceRef.current) {
      await update(firebaseLocationReferenceRef.current, {
        formSubmitDate: serverTimestamp(),
        url: window.location.href,
        formValues,
        uid,
      })

    } else {
      const firebaseLocationRefeference = await push(ref(getDatabase(firebaseApp), 'feedback/entries'), {
        formSubmitDate: serverTimestamp(),
        url: window.location.href,
        formValues,
        uid,
      })

      return firebaseLocationRefeference
    }
  }
}

function EmojiRadioGroup({ field, options, onChange }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value } = state

  return (
    <div className={styles.componentEmojiRadioGroup}>
      {options.map(option => {
        const id = `${name}_${option.label}`
        const checked = value === option.value.toString()

        return (
          <div key={id} className={styles.radioItem}>
            <input
              type='radio'
              value={option.value}
              className={styles.inputEmojiRadio}
              onChange={() => handleClick(option.value)}
              {...{ name, id, checked }}
            />
            <label
              htmlFor={id}
              aria-label={`Mijn ervaring was ${option.label}`}
              className={cx(styles.radioLabel, checked && styles.checked)}
            >
              <span className={styles.emoji}>{option.emoji}</span>
              <span>{option.label}</span>
            </label>
          </div>
        )
      })}
    </div>
  )

  function handleClick(x) {
    eventHandlers.onChange(x)
    onChange(x)
  }
}

function SubmitButton() {
  const { __ } = useTranslate()
  return <ButtonBase type='submit' label={__`feedback-submit`} dataX='submit-feedback-form' />
}

function DismissButton({ onClick }) {
  const { __ } = useTranslate()
  return <ButtonBase type='button' label={__`feedback-dismiss`} dataX='dismiss-feedback-form' {...{ onClick }} />
}

function ButtonBase({ type, label, dataX, onClick = undefined }) {
  return (
    <button data-x={dataX} className={styles.componentButtonBase} {...{ type, onClick }}>
      <span className={styles.buttonLabel}>{label}</span>
      <span className={styles.buttonIconContainer}>
        <Icon icon={iconArrowRight} layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )
}

function useFloatingProps({ isOpen, handleOpenChange }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: handleOpenChange
  })

  const dismiss = useDismiss(context)
  const focus = useFocus(context)
  const role = useRole(context, { role: 'alertdialog' })

  const { getFloatingProps } = useInteractions([dismiss, focus, role])

  return { refs, getFloatingProps: x => getFloatingProps({ ...x, ref: refs.setFloating }) }
}

function getPlaceholderText(language, value) {
  switch (value) {
    case 'very-bad': return translateSingular(language, `feedback-placeholder-very-bad`)
    case 'bad': return translateSingular(language, `feedback-placeholder-bad`)
    case 'average': return translateSingular(language, `feedback-placeholder-average`)
    case 'good': return translateSingular(language, `feedback-placeholder-good`)
    case 'very-good': return translateSingular(language, `feedback-placeholder-very-good`)
    default: return translateSingular(language, `feedback-placeholder-default`)
  }
}
